export default {
    language: {
        romanian: 'Romana',
        english: 'Engleza'
    },
    app: {
        welcome: "FISCA",
        footer: {
            copyright: '© {year} Arnia Software. Toate drepturile rezervate.'
        },
    },
    validation: {
        required: 'Camp obligatoriu',
        password: {
            match: 'Parolele nu corespund'
        }
    },
    ui: {
        camera: {
            "not-allowed-message": "Accesul la camera este blocat. Va rugam sa permiteti accesul pentru a putea fotografia",
            "take-photo": "Fotografiaza"
        }
    },
    tournament: {
        menu: {
            title: 'Turnee'
        },
        fields: {
            name: 'Nume',
            round: 'Runde'
        },
        game: {
            round: 'Runda',
            view: 'Vezi',
            download: 'Descarca',
            board: 'Masa',
            white: 'Alb',
            black: 'Negru',
            file: 'Fisa',
            result: 'Rezultat',
            name: 'Nume',
            processImage: 'Proceseaza',
            reprocessImage: 'Reproceseaza',
            processing: 'Se proceseaza',
            pgn: 'PGN',
            enableProcessing: 'Activeaza procesarea',
            no: "Nu exista jocuri",
            results: "Rezultate",
            noResults: "Rezultatele vor aparea dupa incheierea fiecarei runde",
            action: "Actiuni",
            pgnCopied: 'PGN-ul a fost copiat',
            pgnNotCopied: 'PGN-ul nu a putut fi copiat',
            retry: 'Reincearca',
            pdfCopied: 'PDF-ul a fost copiat',
            pdfNotCopied: 'PDF ul nu a putut fi copiat'
        },
        ui: {
            camera: {
                "take-photo": "Fotografiaza"
            }
        },
        image: {
            view: 'Vezi',
            upload: 'Foto'
        },
        button: {
            save: 'Salveaza' ,
            cancel: 'Anuleaza',
            crop: 'Decupeaza',
            photo: 'Fotografiaza',
            browse: 'Cauta poza',
            reimport: 'Reimporta runda',
            success: 'Runda a fost reimportata cu succes',
            error: 'Runda NU a fost reimportata',
            pdf: 'Genereaza PDF',
            delete: 'Sterge',
            replace: 'Inlocuieste'
        },
        pdf: {
            new: 'GENERATI UN NOU PDF\n\ncu ultimele modificari',
            success: 'PDF generat cu succes',
            error: 'Eroare la generarea PDF-ului',
            view: 'Vezi PDF',
            copy: 'Copiaza PDF link',
        },
        reimport: {
            success: 'Runda a fost reimportata cu succes',
            error: 'Runda NU a fost reimportata',
        },
        modal: {
            upload: "Trage & pune poza pentru incarcare",
            uploadInfo: "Incarca o singura poza pentru un jucator cu toate paginile in ordine",
            supported: 'Fisiere suportate: PNG, JPG - max. 30 MB',
            or: 'ori',
            header: 'Incarca poza',
            save: {
                success: 'Fisa a fost salvata cu succes',
                error: 'A aparut o eroare la salvarea fisei'
            },
        },
        modalEdit: {
            header: 'Editeaza fisa'
        },
        header: {
            title: "FISCA",
            chessresults: 'Vizualizeaza pe chess-results.com'
        },
        fileRejections: {
            "file-too-large" : "Fisierul este mai mare de 30 MB",
            "file-invalid-type" : "Tipul fișierului trebuie să fie *.jpg, *.jpeg, *.png",
            "too-many-files" : "Acceptăm un singur fișier de tip *.jpg, *.jpeg, *.png"
        }
    },
    user: {
        actions: {
            'delete-confirm': 'Esti sigur ca vrei sa stergi utilizatorul "{firstName} {lastName}"'
        },
        add: {
            new: 'Adauga utilizator',
        },
        buttons: {
            'add-new': "Adauga utilizator nou"
        },
        create: {
            new: 'Creaza utilizator nou'
        },
        dropdown: {
            empty: '--Selecteaza utilizator--'
        },
        edit: {
            label: 'Editeaza utlizatorul "{firstName} {lastName}"',
        },
        error: {
            'email-exists': 'Adresa de e-mail exista deja'
        },
        fields: {
            'current-password': 'Parola curenta',
            email: 'E-mail',
            firstName: 'Prenume',
            language: "Limba",
            lastName: 'Nume',
            password: 'parola',
            're-password': 'Reintrodu parola',
            user: 'Utilizator'
        },
        info: {
            label: 'Informatii utilizator'
        },
        load: {
            missing: "Utilizatorul cerut nu exista",
            'load-error': 'A aparut o eroare la incarcarea utilizatorului: "{error}"'
        },
        menu: {
            title: 'Utilizatori'
        },
        "no-results": "Nu exista utilizatori",
        save: {
            success: 'Utilizatorul "{firstName} {lastName}" A fost salvat cu succes',
            error: 'A aparut o eroare la salvarea utilizatorului: "{error}"'
        },
        view: {
            label: 'Vizualizare utilizator "{firstName} {lastName}"',
        },
    },
    'my-account': {
        'change-password': {
            error: 'A aparut o eroare la schimbarea parolei: {error}',
            'incorrect-current-password': 'Parola curenta incorecta',
            label: 'Schimba parola',
            success: 'Parola schimbata cu succes'
        },
        info: 'Detalii cont',
        load: {
            error: 'A aparut o eroare la incarcarea detaliilor de cont: {error}'
        },
        menu: {
            title: 'Contul meu'
        },
        save: {
            success: 'Detalii cont salvate cu succes',
            error: 'A aparut o eroare la salvarea detaliilor de cont: {error}'
        }
    },
    logout: {
        menu: {
            title: "Deconectare"
        }
    },
    login: {
        buttons: {
            login: 'Autentificare'
        },
        fields: {
            email: 'Adresa de e-mail',
            password: 'Parola'
        },
        'forgot-password': {
            message: 'Ai uitat parola?',
            recover: 'recupereaz-o'
        },
        invalid: {
            credentials: 'Date de autentificare invalide'
        },
        menu: {
            title: 'Autentificare'
        },
        error: {
            'generic': 'Ceva nu a mers bine, vă rugăm să încercați din nou în câteva minute.'
        },
    },
    register: {
        'existing-account': 'Ai deja cont?',
        label: 'Inregistrare',
        menu: {
            title: 'Inregistrare',
        },
        'no-account': 'Nu ai un cont inca?',
        success: 'Inregistrare cu succes. Vei primi un mail cu instructiuni de setare a parolei'

    },
    'recover-password': {
        label: 'Recuperare parola',
        login: 'Inapoi la pagina de autentificare.',
        message: 'introdu adresa de email cu care ti-ai facut cont si iti vom trimite un mail cu instructiuni de resetare a parolei',
        recover: 'recuperare',
        'success-message': 'Ti-am trimis un email cu pasul urmator'
    },
    'reset-password': {
        buttons: {
            change: 'Schimbare parola'
        },
        error: 'A aparut o eroare la resetarea parolei',
        'missing-reset-code': 'Codul de resetare a parolei nu este valid.',
        success: 'Parola a fost resetata cu succes',
        'title': 'REsetare parola'
    },
}
